const providedValues = [
  "Bag",
  "Bundles",
  "Bale",
  "Buckles",
  "Billions Of Units",
  "Box",
  "Bottles",
  "Bunches",
  "Cans",
  "Cartons",
  "Dozen",
  "Drum",
  "Great Gross",
  "Gross",
  "Numbers",
  "Packs",
  "Pieces",
  "Pairs",
  "Rolls",
  "Sets",
  "Tablets",
  "Ten Gross",
  "Thousands",
  "Tubes",
  "Units",
  "Cubic Meter",
  "Cubic Centimeter",
  "Kilo Liter",
  "Milliliter",
  "US Gallons",
  "Square Feet",
  "Square Meters",
  "Square Yards",
  "Gross Yards",
  "Kilo Meter",
  "Meters",
  "Yards",
  "Centimeter",
  "Tonnes",
  "Quintal",
  "Grams",
  "Kilo Grams",
  "Others",
];

export const selectUnitQty = providedValues.map((value) => ({
  label: value,
  value: value,
}));

export const listOfProgressFront = [
  {
    name: "Introduction",
    value: 1,
    color: "#ffffff",
    bg: "#f55249",
  },
  {
    name: "Potential Mapping",
    value: 2,
    color: "#ffffff",
    bg: "#e67e43",
  },
  {
    name: "Inquiry",
    value: 3,
    color: "#000000",
    bg: "#ffc14d",
  },
  {
    name: "Trial/Demo",
    value: 4,
    color: "#00000",
    bg: "#fcee53",
  },
  {
    name: "Quotation",
    value: 5,
    color: "#ffffff",
    bg: "#bdbdbd",
  },
  {
    name: "Follow Up",
    value: 6,
    color: "#000000",
    bg: "#34ebcc",
  },
  {
    name: "Negotiation",
    value: 7,
    color: "#000000",
    bg: "#4fcbdb",
  },
  {
    name: "Finalization",
    value: 8,
    color: "#000000",
    bg: "#b9f78f",
  },
  {
    name: "Part Order Converted",
    value: 9,
    color: "#000000",
    bg: "#79cf63",
  },
  {
    name: "Customer Converted",
    value: 10,
    color: "#000000",
    bg: "#2bb526",
  },
];

export const addEditDeleteRightsManagementList = [
  {
    label: "Create Funnel",
    value: "createFunnel",
    isView: true,
    isAddEditDelete: true,
  },
  {
    label: "Funnel Data Entry",
    value: "funnel",
    isView: true,
    isAddEditDelete: true,
  },
  {
    label: "Customer Files",
    value: "customerFiles",
    isView: true,
    isAddEditDelete: true,
  },
  {
    label: "Products",
    value: "products",
    isView: true,
    isAddEditDelete: true,
  },
  // {
  //   label: "Reporting",
  //   value: "reporting",
  //   isView: true,
  //   isAddEditDelete: true,
  // },
  {
    label: "Preset",
    value: "preset",
    isView: true,
    isAddEditDelete: true,
  },
  // {
  //   label: "Users File",
  //   value: "usersFile",
  //   isView: true,
  //   isAddEditDelete: true,
  // },
  // {
  //   label: "Product Pricing",
  //   value: "productPricing",
  //   isView: true,
  //   isAddEditDelete: true,
  // },
];

export const viewOnlyRightsManagementList = [
  {
    label: "Charts",
    value: "charts",
    isView: true,
    isAddEditDelete: false,
  },
  {
    label: "Funnel",
    value: "funnel",
    isView: true,
    isAddEditDelete: false,
  },
  {
    label: "Customer Files",
    value: "customerFiles",
    isView: true,
    isAddEditDelete: false,
  },
  {
    label: "Products",
    value: "products",
    isView: true,
    isAddEditDelete: false,
  },
  // {
  //   label: "Reporting",
  //   value: "reporting",
  //   isView: true,
  //   isAddEditDelete: false,
  // },
  {
    label: "Preset",
    value: "preset",
    isView: true,
    isAddEditDelete: false,
  },
  // {
  //   label: "Users File",
  //   value: "usersFile",
  //   isView: true,
  //   isAddEditDelete: false,
  // },
  // {
  //   label: "Product Pricing",
  //   value: "productPricing",
  //   isView: true,
  //   isAddEditDelete: false,
  // },
];

export const downloadRightsManagementList = [
  // {
  //   label: "Download Excel",
  //   value: "downloadExcel",
  //   isDownload: true,
  // },
];

export const monthsArrayList = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
